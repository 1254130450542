<template>
    <div>
        <div v-if="!city">
            <!-- Search Form -->
            <div class="card">
                <form method="post" @submit.prevent="search">
                    <div class="flex">
                        <div class="flex-grow">
                            <input
                                type="text"
                                class="input"
                                v-model="searchFormData.postcode"
                                placeholder="Postcode..."
                            />
                        </div>
                        <button class="btn ml-3" type="submit" :disabled="searching">Zoeken</button>
                    </div>
                </form>
            </div>
            <!-- End Search Form -->

            <!-- Cities List -->
            <div v-if="cities" class="flex flex-wrap -mr-2 -ml-2">
                <div v-for="c in cities" :key="c.id" class="px-2 w-1/4 pt-4">
                    <a
                        href="#"
                        @click.prevent="city = c"
                        class="py-4 px-6 bg-white border block rounded-lg border-gray-200 p-8 text-center"
                    >{{ c.name }}</a>
                </div>
            </div>
            <!-- End Cities List -->
        </div>
        <div v-if="users">
            <div class="card">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="border-b text-gray-800 pr-4 pb-3 text-left">Naam</th>
                            <th class="border-b text-gray-800 pr-4 pb-3 text-left">Gemeente</th>
                            <th class="border-b text-gray-800 pr-4 pb-3 text-left">Afstand</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="u in users" :key="u.uuid">
                            <td class="border-b pr-4 py-3">
                                <label>
                                    <input
                                        type="checkbox"
                                        v-model="ticketFormData.assignee"
                                        :value="u.uuid"
                                    />
                                    {{u.name}}
                                </label>
                            </td>
                            <td class="border-b pr-4 py-3">{{ u.city }}</td>
                            <td class="border-b pr-4 py-3">{{ u.distance }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        city(newVal, oldVal) {
            if (newVal) {
                this.getUsers();
            }
        }
    },
    data() {
        return {
            users: null,
            cities: null,
            searching: false,
            searchFormData: {
                postcode: ""
            },
            ticketFormData: {
                assignee: null,
                client_first_name,
                client_last_name,
                client_email,
                client_phone,
                client_address_line_1,
                client_address_line_2,
                client_postcode,
                client_city,
                client_country,
                product,
                product_shop,
                product_brand
            },
            city: null
        };
    },
    methods: {
        search() {
            if (this.searching) {
                return false;
            }

            this.searching = true;

            axios
                .post("/api/cities", this.searchFormData)
                .then(res => {
                    console.log(res);
                    this.cities = res.data.data;
                    this.searching = false;
                })
                .catch(err => {
                    console.log(err);
                    this.searching = false;
                });
        },

        getUsers() {
            if (this.searching) {
                return false;
            }

            this.searching = true;

            axios
                .post("/api/users", {
                    lat: this.city.lat,
                    lng: this.city.lng
                })
                .then(res => {
                    console.log(res);
                    this.users = res.data.data.map(u => {
                        if (u.distance < 1) {
                            u.distance = u.distance.toFixed(2) + " m";
                            return u;
                        }
                        u.distance = u.distance.toFixed(2) + " km";
                        return u;
                    });
                    this.searching = false;
                })
                .catch(err => {
                    console.log(err);
                    this.searching = false;
                });
        }
    }
};
</script>
