<template>
    <div>
        <!-- Toggle Modal -->
        <a
            href="#"
            @click.prevent="showModal = true"
            class="text-sm bg-indigo-500 text-sm py-1 px-3 rounded text-white inline-block"
        >Assign</a>
        <!-- End Toggle Modal -->

        <!-- Modal -->
        <div v-if="showModal">
            <div
                class="fixed z-10 bg-gray-800 top-0 left-0 right-0 bottom-0 opacity-75"
                @click.prevent="showModal = false"
            ></div>
            <div
                class="fixed flex z-20 top-0 left-0 h-full w-full items-center justify-center py-4 px-4"
            >
                <div class="bg-white p-6 max-w-full w-1/3 rounded-lg shadow-xl">
                    <div class="flex justify-between items-center mb-8">
                        <h2 class="text-xl font-bold">Assign to user</h2>
                        <a href="#" @click.prevent="showModal = false">
                            <i class="la la-times"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </div>
</template>

<script>
export default {
    props: {
        ticket: {}
    },
    data() {
        return {
            showModal: false
        };
    }
};
</script>
